import Oidc from 'oidc-client';
import 'regenerator-runtime/runtime';

class AuthService  {
    userManager;
    constructor() {    
        this.userManager =  new  Oidc.UserManager({
            authority: 'https://applogin.talygen.com',
            //authority: 'https://devlogin.talygen.devlabs.com',
            //  authority: 'https://stagelogin.talygen.com',
            client_id: 'talygenspa',
            redirect_uri:'https://appone.talygen.com/Content/Callback.html',
            response_type: 'id_token token',
            scope: 'openid profile',
            post_logout_redirect_uri: 'https://appone.talygen.com',
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            silent_redirect_uri: 'https://appone.talygen.com/Content/silent-renew.html',
            accessTokenExpiringNotificationTime: 10,
            monitorSession: true
        });

    //     this.userManager =  new  Oidc.UserManager({
    //        //authority: 'https://applogin.talygen.com',
    //        //authority: 'https://devlogin.talygen.devlabs.com',
    //        authority: 'https://stagelogin.talygen.com',
    //        client_id: 'talygenspa',
    //        redirect_uri:'https://stagelogin.talygen.com/Content/Callback.html',
    //        response_type: 'id_token token',
    //        scope: 'openid profile',
    //        post_logout_redirect_uri: 'https://stagelogin.talygen.com',
    //        userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    //        automaticSilentRenew: true,
    //        silent_redirect_uri: 'https://stagelogin.talygen.com/Content/silent-renew.html',
    //        accessTokenExpiringNotificationTime: 10,
    //        monitorSession: true
    //    });
    
          // FOR USIC TEST
       /*  
       this.userManager = new Oidc.UserManager({
        authority: 'https://usiclogin.talygen.com',
        client_id: 'talygenspa',
        redirect_uri:'https://usictestnewui.talygen.com/Content/Callback.html',
        response_type: 'id_token token',
        scope: 'openid profile',
        post_logout_redirect_uri: 'https://usictestnewui.talygen.com',
        userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        silent_redirect_uri: 'https://usictestnewui.talygen.com/Content/silent-renew.html',
        accessTokenExpiringNotificationTime: 10,
        monitorSession: true
       });
        */

        // this.userManager.events.addUserSignedOut(function () {
        //     this.userManager.signoutRedirect().then(function (resp) {
        //         store.commit('set_user', null); //clear user details in vuex
        //     }).catch(function (err) {
        //         console.log(err)
        //     })d
        // });
    }
    signIn (returnToUrl) {        
        returnToUrl ? this.userManager.signinRedirect({ state: returnToUrl })
            : this.userManager.signinRedirect();
    }
    async authenticate(returnPath) {     
      
        const user = await this.getUser(); //see if the user details are in local storage       
        if (!!user) {            
            //await useloggedUserInfo().setUser(user);
        } else {            
            await this.signIn(returnPath);
        }
    }
    async logOut(){
        try {
            var usr=this.getUser();
            if(usr)
            {
            await this.userManager.signoutRedirect( {id_token_hint: usr.Token,                 
                            post_logout_redirect_uri : this.userManager.post_logout_redirect_uri});
                           
            this.userManager.clearStaleState();
            }
            else
            {
                await this.userManager.signoutRedirect( {post_logout_redirect_uri : this.userManager.post_logout_redirect_uri});
                   
                this.userManager.clearStaleState()
            }
        }catch(error){
            console.log(error);
        }
       
    }
    async isLoggedIn() {
        const user = await this.userManager.getUser();
        return !!user && !user.expired;
      }
    async getUser() {
        try {
            const user = await this.userManager.getUser();
            return user;
        } catch (err) {
            console.log(err);
        }
    }
    async CheckPrivilege(privilegeName, showalert, doredirect) {
        //return (privilegeName.toLocaleLowerCase().includes("deal"))?false: true;
        try{
        if (privilegeName == "" || privilegeName.length == 0) return true;
        let haspermission = true;
        //let data1 = http.instance.get(scriptUrl, { privilegeName: privilegeName });
        if (data1)
            if (!data1.hasPermission) {
                haspermission = false;
                 if (showalert) alert(data1.message);
            } else {
              //  if (doredirect) window.location = hrefLink;
                haspermission = true;
            }
        return haspermission;
        }catch(e){ return true}
    }
}
export default new AuthService();